<template>
  <div class="google-address-autocomplete">
    <input
      :id="id"
      :ref="`google-autocomplete-${id}`"
      v-model="completeAddress"
      :disabled="disabled"
      :autocomplete="complete"
      type="text"
      :class="[ 'google-address-autocomplete__input', className, disabled ? 'kovi-modal-step-address__disabled' : '' ]"
      :style="[ newStyle ]"
      :placeholder="placeholder"
      @focus="inputFocus"
      @blur="inputBlur"
    >
  </div>
</template>

<script>
export default {
  name: 'GoogleAddressAutocomplete',
  props: {
    id: {
      type: String,
      default: 'kovi-address-autocomplete'
    },
    className: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: 'BR'
    },
    locale: {
      type: String,
      default: 'BR'
    },
    placeholder: {
      type: String,
      default: 'Digite seu endereço'
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    complete: {
      type: String,
      default: 'on',
      validator: (value) => {
        return ['off', 'on'].indexOf(value) !== -1
      }
    },
    paddingTop: {
      type: [String, Number],
      default: '0'
    },
    paddingBottom: {
      type: [String, Number],
      default: '0'
    },
    paddingLeft: {
      type: [String, Number],
      default: '0'
    },
    paddingRight: {
      type: [String, Number],
      default: '0'
    },
    background: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: '000000'
    },
    borderWidth: {
      type: String,
      default: '1px'
    },
    borderColor: {
      type: String,
      default: '0,0,0'
    },
    borderRadius: {
      type: String,
      default: '0px'
    },
    borderOpacity: {
      type: String,
      default: '1'
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: (value) => {
        return ['left', 'center', 'right'].includes(value)
      }
    },
  },
  data() {
    return {
      address: {},
      autocomplete: {},
      completeAddress: ''
    }
  },
  computed: {
    newStyle () {
      return {
        background: this.background,
        color: this.color,
        border: `${this.borderWidth} solid rgba(${this.borderColor}, ${this.borderOpacity})`,
        'border-radius': this.borderRadius,
        padding: `${this.paddingTop}px ${this.paddingRight}px ${this.paddingBottom}px ${this.paddingLeft}px`,
      }
    }
  },
  watch: {
    value (value) {
      this.completeAddress = value
    },
    clear (value) {
      if (value) this.completeAddress = ''
    }
  },
  mounted() {
    // Obtendo o input
    const input = this.$refs[`google-autocomplete-${this.id}`]

    const country = this.locale

    // Limitando o tipo e pais
    const options = {
      types: ['geocode'],
      componentRestrictions: {
        country
      },
      strictBounds: true
    }

    // Instanciando a pesquisa por local no input
    this.autocomplete = new google.maps.places.Autocomplete(input, options)

    // Definindo os campos retornandos quando selecionado um local
    this.autocomplete.setFields(['address_component', 'geometry', 'types', 'formatted_address'])

    this.autocomplete.addListener('place_changed', this.placeChanged)
  },
  methods: {
    placeChanged() {
      const address = this.autocomplete.getPlace()
      let numberNotFound = false

      if (!address.geometry || address.hasOwnProperty('name')) {
        this.emitSelectedValue({})
        return
      }

      if (address.address_components[0].types[0] !== 'street_number') {
        numberNotFound = true
      }

      address.numberNotFound = numberNotFound

      this.$emit('selected-value', address)
    },
    emitSelectedValue (value = '') {
      this.$emit('selected-value', value)
    },
    inputFocus () {
      this.$emit('focus')
    },
    inputBlur () {
      if (!this.completeAddress) {
        this.emitSelectedValue()
      }

      this.$emit('blur')
    }
  },
}
</script>

<style lang="scss">
.google-address-autocomplete {
  .google-address-autocomplete__input {
    width: 100%;
    opacity: 1;

    &.kovi-modal-step-address__disabled {
      opacity: 0.3;
    }

    &:focus {
      outline: none;
    }
  }
}
.pac-container {
  z-index: 999999999;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "google-address-autocomplete" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.completeAddress,
          expression: "completeAddress",
        },
      ],
      ref: `google-autocomplete-${_vm.id}`,
      class: [
        "google-address-autocomplete__input",
        _vm.className,
        _vm.disabled ? "kovi-modal-step-address__disabled" : "",
      ],
      style: [_vm.newStyle],
      attrs: {
        id: _vm.id,
        disabled: _vm.disabled,
        autocomplete: _vm.complete,
        type: "text",
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.completeAddress },
      on: {
        focus: _vm.inputFocus,
        blur: _vm.inputBlur,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.completeAddress = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }